import { render, staticRenderFns } from "./TemplateFraisCommerciaux.vue?vue&type=template&id=79d2cb90&scoped=true"
import script from "./TemplateFraisCommerciaux.vue?vue&type=script&lang=js"
export * from "./TemplateFraisCommerciaux.vue?vue&type=script&lang=js"
import style0 from "./TemplateFraisCommerciaux.vue?vue&type=style&index=0&id=79d2cb90&prod&lang=scss&scoped=true"
import style1 from "./TemplateFraisCommerciaux.vue?vue&type=style&index=1&id=79d2cb90&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79d2cb90",
  null
  
)

export default component.exports