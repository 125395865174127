<template>
  <div class="template-facture-mensuel">
    <span
      class="default-color-statue"
      :class="{
        'black-color-statue': item.etat == 'Créé',
        'green-color': item.etat == 'Payé',
        'red-color montant-bare': item.etat == 'Annulée'
      }"
    >
      {{ item.ttc }} €
    </span>
    <div v-if="item.id != null && checkPermission('FCTHTFP')">
      <font-awesome-icon
        icon="arrow-alt-circle-down"
        @click.prevent="downloadFacture()"
        class="icon-style-color mr-1"
        title="Télécharger la facture"
      />
    </div>
    <div v-if="item.etat == 'Annulée' && checkPermission('FCTHTFAP')">
      <font-awesome-icon
        icon="arrow-alt-circle-down"
        @click.prevent="downloadFactureAvoir()"
        class="icon-style-color mr-1 button-export-style-avoir"
        title="Télécharger la facture avoir"
      />
    </div>
    <div
      :class="
        item.email_sent_at == null
          ? 'envelop-icon-container mr-1'
          : 'background-email-sent mr-1'
      "
      v-if="download == true"
    >
      <font-awesome-icon
        icon="envelope"
        class=" 'icon-style-color envelop-icon mr-1"
      />
    </div>
    <font-awesome-icon
      v-if="item.id && editable"
      icon="file-code"
      class="icon-style-color"
      title="Valider la facture"
      @click.prevent="getTemplateFacture"
    />
    <b-modal
      no-close-on-backdrop
      ref="uploadFactureTemplateModal"
      id="uploadFactureTemplateModal"
      size="lg"
      :hide-footer="true"
      :hide-header="true"
      modal-class="cutsom-modal-bootstrap  custom-modal-template-facture-frais "
    >
      <div
        class="iconClose"
        @click.prevent="hideModal('uploadFactureTemplateModal')"
      >
        <font-awesome-icon icon="times" />
      </div>
      <div class="message text-aligne-center">
        <div v-if="loader == true" class="loading-custom-template-block">
          <Half-circle-spinner
            :animation-duration="1000"
            :size="50"
            :color="'#704ad1'"
            class="loeder"
          />
        </div>
        <div v-if="errorTemplateNotFound" class="error-message">
          <div class="error">
            {{ errorTemplateNotFound }}
          </div>
        </div>
      </div>
      <div
        class="template-block-style"
        v-if="templateFactureCommercial"
        id="hmt_block_id"
      >
        <div class="input-fields-style-for-template">
          <div class="form-actions mt-3 mb-5 text-aligne-center">
            <b-button
              class="button-succes-style ml-2"
              size="sm"
              variant="success"
              @click="handleUpload"
            >
              Valider
              <font-awesome-icon icon="arrow-alt-circle-right" class="ml-2" />
            </b-button>
            <div
              v-if="loadingUpload == true"
              class="loading-custom-template-block"
            >
              <Half-circle-spinner
                :animation-duration="1000"
                :size="50"
                :color="'#704ad1'"
                class="loeder"
              />
            </div>
            <div class="message text-aligne-center">
              <div v-if="errorUpload" class="error-message">
                <div class="error">{{ errorUpload }}</div>
              </div>
            </div>
          </div>
          <div class="fields-input">
            <b-row>
              <b-col col xs="12" sm="12" md="12">
                <b-form-group
                  label="Date de Facture"
                  label-for="title-template-swift"
                  class="input-modal-champ "
                >
                  <date-picker
                    :disabled="true"
                    value-type="format"
                    type="date"
                    v-model="templateFactureCommercial.date"
                    class="inputDateTemplate"
                  ></date-picker>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- payment condition-->
              <b-col col xs="12" sm="12" md="12">
                <!-- payment condition  -->
                <b-form-group
                  label="Conditions du paiement"
                  label-for="payement-condition"
                >
                  <multiselect
                    v-model="templateFactureCommercial.payment_condition"
                    :options="computedConditionPaiement"
                    :multiple="false"
                    label="name"
                    track-by="id"
                    required
                    class="multiselect-vue-custom-style-cdg"
                    :showLabels="false"
                  >
                  </multiselect>
                </b-form-group>
              </b-col>
            </b-row>
            <div>
              <b-form-group
                label="Description "
                label-for="title-template-description"
                class=" input-modal-champ "
              >
                <b-form-textarea
                  id="title-template-description"
                  v-model="templateFactureCommercial.description"
                  rows="5"
                ></b-form-textarea>
              </b-form-group>
            </div>
            <b-col col xs="12" sm="12" md="12">
              <b-form-group label="Rib" label-for="rib-template-select">
                <multiselect
                  v-model="rib"
                  :options="templateFactureCommercial.ribs"
                  :multiple="false"
                  label="rib"
                  track-by="rib"
                  required
                  class="multiselect-vue-custom-style-cdg"
                  :showLabels="false"
                >
                </multiselect>
              </b-form-group>
            </b-col>

            <b-form-group
              label="Choisissez une template"
              class=" input-modal-champ "
              label-for="template-list"
            >
              <b-form-radio-group
                id="radio-group-1"
                v-model="templateFactureCommercial.id_template"
                :options="templateFactureCommercial.templates"
                name="radio-options"
                value-field="id"
                text-field="name"
                stacked
                @change="changeTemplate()"
              ></b-form-radio-group>
            </b-form-group>
            <div
              class="message text-aligne-center"
              v-if="errorUploadRib != null"
            >
              <div class="error-message-rib">
                <div class="error">{{ errorUploadRib }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="div-content-loader-template">
          <div
            v-if="getLoadingGetDataTemplateFM"
            class="loading-custom-template-block loading-custom-template-block-mf"
          >
            <Half-circle-spinner
              :animation-duration="1000"
              :size="50"
              :color="'#704ad1'"
              class="loeder"
            />
          </div>
          <iframe
            v-if="templateFactureCommercial && templateFactureCommercial.base64"
            :src="templateFactureCommercial.base64 + '#toolbar=0'"
            width="1000"
            height="900"
            id="hmt_template_block_id"
            class="template-style"
          ></iframe>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      errorTemplateNotFound: null,
      loader: false,
      rib: null,
      errorUploadRib: null,
      getLoadingGetDataTemplateFM: false,
      loadingUpload: false,
      errorUpload: null
    };
  },
  props: {
    item: { required: true },
    project: { required: true },
    componentName: { required: true },
    editable: { required: true },
    download: { required: false }
  },
  computed: {
    ...mapGetters([
      'templateFactureCommercial',
      'loadingMensuel',
      'masterFilialeTemplateList',
      'checkPermission',
      'ConditionPaiement'
    ]),
    computedConditionPaiement() {
      var table = this.ConditionPaiement.map(item => {
        return {
          name: item.name,
          id: item.id
        };
      });
      return [{ name: '', id: null }, ...table];
    }
  },
  methods: {
    ...mapActions([
      'getTemplateFacturesCommerciaux',
      'getTemplateAndFiledsDataFraisDynamicToUpdateChange',
      'getSocieteTemplatesListMF',
      'validateFactureCommercialTemplate',
      'exportPdfFactureMensuel',
      'getAllConditionsPaiement'
    ]),
    async downloadFactureAvoir() {
      await this.exportPdfFactureMensuel({
        id: this.item.avoir_id,
        id_template: this.item.template_id == null ? -1 : this.item.template_id,
        id_master: this.item.vendeur_id
      });
    },
    async downloadFacture() {
      await this.exportPdfFactureMensuel({
        id: this.item.id,
        id_template: this.item.template_id == null ? -1 : this.item.template_id,
        id_master: this.item.vendeur_id
      });
    },
    async handleUpload() {
      this.loadingUpload = true;
      this.errorUpload = null;
      this.errorUploadRib = null;
      if (this.rib == null) {
        this.loadingUpload = false;
        this.errorUpload = 'Une erreur est servenue ! RIB obligatoire';
      } else {
        let response = await this.validateFactureCommercialTemplate({
          description: this.templateFactureCommercial.description,
          iban: this.rib.iban,
          swift: this.rib.swift,
          bank: this.rib.bank,
          num_facture: this.templateFactureCommercial.num_facture,
          item: this.item,
          date: this.templateFactureCommercial.date,
          date_reglement: this.templateFactureCommercial.date_reglement,
          id_facture: this.item.id,
          id_template: this.templateFactureCommercial.id_template,
          payment_condition: this.templateFactureCommercial.payment_condition
        });
        if (response?.success) {
          this.loadingUpload = false;
          this.errorUpload = null;
          this.hideModal('uploadFactureTemplateModal');
        } else {
          this.errorUpload = response?.error;
          this.loadingUpload = false;
        }
      }
    },
    async changeTemplate() {
      let selectedTemplte = this.templateFactureCommercial.templates.filter(
        item => item.id_template == this.templateFactureCommercial.id_template
      );
      if (selectedTemplte.length && selectedTemplte[0].origin) {
        this.templateFactureCommercial.template =
          selectedTemplte.content.template;
        this.templateFactureCommercial.base64 = selectedTemplte.content.base64;
        this.getLoadingGetDataTemplateFM = false;
        return;
      }
      this.getLoadingGetDataTemplateFM = true;
      const response = await this.getTemplateAndFiledsDataFraisDynamicToUpdateChange(
        {
          id_template:
            this.templateFactureCommercial.id_template == null
              ? -1
              : this.templateFactureCommercial.id_template,
          id_facture: this.item.id,
          id_master: this.item.filiale_id
        }
      );
      if (response.succes) {
        this.templateFactureCommercial.template = response.data.template;
        this.templateFactureCommercial.base64 = response.data.base64;
        this.getLoadingGetDataTemplateFM = false;
      } else {
        this.getLoadingGetDataTemplateFM = false;
      }
    },
    changeFieldsValue(item) {
      if (item && item.e) {
        this.templateFactureCommercial[item.champName] = item.e;
      }
    },
    hideModal(ref) {
      this.$refs[ref].hide();
    },
    async getTemplateFacture() {
      this.errorTemplateNotFound = null;
      this.$refs['uploadFactureTemplateModal'].show();
      this.loader = true;
      this.getAllConditionsPaiement();

      if (this.item.template_id != null) {
        this.getTemplateData({
          id_template: this.item.template_id,
          id_facture: this.item.id,
          id_master: this.item.filiale_id
        });
      } else {
        const response = await this.getSocieteTemplatesListMF('commercial');
        if (response) {
          if (this.masterFilialeTemplateList.length != 0) {
            this.getTemplateData({
              id_template: this.masterFilialeTemplateList[0].id,
              id_facture: this.item.id,
              id_master: this.item.filiale_id
            });
          } else {
            this.getTemplateData({
              id_template: -1,
              id_facture: this.item.id,
              id_master: this.item.filiale_id
            });
            this.loader = false;
          }
        }
      }
    },
    async getTemplateData(data) {
      const response = await this.getTemplateFacturesCommerciaux(data);
      if (response) {
        if (data.id_template != null) {
          this.templateFactureCommercial.id_template = data.id_template;
        }
        let now = new Date();
        let dateFormate = moment(now).format('YYYY-MM-DD');

        if (this.templateFactureCommercial.day_is_exist == true) {
          if (
            this.templateFactureCommercial &&
            this.templateFactureCommercial.date != null &&
            this.templateFactureCommercial.date != ''
          ) {
            this.templateFactureCommercial.date = moment(
              this.templateFactureCommercial.date
            ).format('YYYY-MM-DD');
          }
        } else {
          if (this.templateFactureCommercial.type_facture != 'Facture') {
            this.templateFactureCommercial.date = dateFormate;
          }
        }
        if (this.templateFactureCommercial.ribs.length) {
          this.rib = this.templateFactureCommercial.ribs[0];
        }
        this.templateFactureCommercial.templates = [
          ...this.templateFactureCommercial.templates,
          { id: -1, name: 'Template Par default' }
        ];
        this.loader = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.button-export-style-avoir {
  color: #e4261b;
  &:hover,
  &:focus {
    color: #e4261b;
  }
}
.template-facture-mensuel {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.template-block-style {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .input-fields-style-for-template {
    width: 39%;
    background-color: #ecedec36;
    margin: 0% 2%;
    padding: 0% 1%;
    border-radius: 16px;
    .fields-input {
      label {
        font-size: 13px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        text-align: start;
        color: #2a2a2a;
        margin-left: 0%;
        margin-top: 0px;
        margin-bottom: 5px;
      }
      .ModelCol {
        display: flex;
      }
    }
  }
  .template-style {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 65px);
    width: 62%;
    padding-right: 6px;
  }
  .input-fields-style-for-template {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 65px);
  }
  .error-message-rib .error {
    color: #000;
    font-size: 16px;
    padding: 10px 20px;
    background: #f3beb8;
    border: 1px solid #f09898;
  }
}
</style>
<style lang="scss">
.input-fields-style-for-template::-webkit-scrollbar,
.template-style::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.input-fields-style-for-template::-webkit-scrollbar-track,
.template-style::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d0cddc;
  border-radius: 10px;
}
.input-fields-style-for-template::-webkit-scrollbar-thumb,
.template-style::-webkit-scrollbar-thumb {
  background: #dadae4;
  border-radius: 7px;
}

.cutsom-modal-bootstrap-libre .modal-dialog {
  top: 0%;
  max-width: 100%;
  margin: 5px;
}
.template-libre-style::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.template-libre-style::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d0cddc;
  border-radius: 10px;
}
.template-libre-style::-webkit-scrollbar-thumb {
  background: #dadae4;
  border-radius: 7px;
}
.fields-input {
  label {
    font-size: 13px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-align: start;
    color: #2a2a2a;
    margin-left: 1%;
    margin-top: 0px;
    margin-bottom: 5px;
  }
  #title-template-description {
    border-radius: 15px;
  }
}
.div-content-loader-template {
  display: contents;
}
.loading-custom-template-block-mf {
  left: 41%;
  .loeder {
    margin: 5px 24%;
  }
}
#template_footer {
  position: relative !important;
}
#num_facture {
  margin-top: 0px !important;
}
</style>
